export const GET_TRANSACTION = "GET_TRANSACTION";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const TRANSACTIONS_LOADING = "TRANSACTIONS_LOADING";

export const GET_ITEM = "GET_ITEM";
export const GET_ITEMS = "GET_ITEMS";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const ITEMS_LOADING = "ITEMS_LOADING";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const API_SUCCESS = "API_SUCCESS";


export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_PENDING = "VERIFICATION_PENDING";
export const VERIFICATION_FAIL = "VERIFICATION_FAIL";


export const IS_VERIFYING = "IS_VERIFYING";

export const HAS_USERNAME = "HAS_USERNAME";

export const UPDATED_ACCOUNT = "UPDATED_ACCOUNT";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const ALBEDO_VERIFICATION_SUCCESS = "ALBEDO_VERIFICATION_SUCCESS";
export const ALBEDO_VERIFICATION_PENDING = "ALBEDO_VERIFICATION_PENDING";
export const ALBEDO_VERIFICATION_FAIL = "ALBEDO_VERIFICATION_FAIL";

export const FIRST_COURSE_DONE = 'FIRST_COURSE_DONE';

export const FIRST_QUESTION_VALID = "FIRST_QUESTION_VALID";
export const SECOND_QUESTION_VALID = 'SECOND_QUESTION_VALID';
export const THIRD_QUESTION_VALID = 'THIRD_QUESTION_VALID';