import React from "react";
import "./style.css";


function ContactUs() {
    return (
        <div className="contactus">
           <h1>Contact Us</h1> 

           <div> Would you like to support the project or you have a question?, if so please send us an email to <a href = "mailto: edunodeapp@gmail.com">edunodeapp@gmail.com</a>  </div>
          
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

            </div>
    );
}

export default ContactUs;