import React, {useState, useEffect, useRef } from 'react'
import io from "socket.io-client";
// import Twit from 'twit'







function Tweets() {



   
    return (
        <div>
 {/* {response} */}
        </div>
    );
}

export default Tweets